<template>
  <div class="payment">
    <el-dialog
      title="上传支付凭证"
      :visible.sync="dialogVisible2"
      :before-close="handleClose"
      width="30%"
    >
      <el-form
        :model="form"
        label-position="left"
        label-width="80px"
        inline
        ref="form"
      >
        <el-form-item
          label="支付凭证·
      "
        >
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            accept=".jpg,.jpeg,.png,.gif"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            style="width: 250px"
            v-model="form.bondNotice"
            placeholder=""
          >
          </el-input>
        </el-form-item>

        <el-form-item
          v-if="payList.status == 6 || payList.status == 4"
          label="审核结果"
        >
          <el-input
            style="width: 250px"
            v-model="form.rejectNotice"
            placeholder=""
            :disabled="payList.status == 6 || payList.status == 4"
          >
          </el-input>
        </el-form-item>
        <div class="allBtn">
          <el-button type="primary" @click="derive()">关闭</el-button>
          <el-button type="primary" @click="uploadBond">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { uploadBond, selectBondDetail } from "@/api/CollectivePurchase";

export default {
  name: "NowPayment",

  data() {
    return {
      dialogVisible2: true,
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`,
      baseUrl: window.globalUrl.HOME_API,

      imageUrl: "",
      form: {
        bondNotice: "",
        rejectNotice: "",
      },
      payList: [],
      ImgList: [],
    };
  },

  mounted() {},

  methods: {
    //关闭弹窗
    handleClose() {
      this.$emit("closeDialog2", false);
    },
    derive() {
      this.$emit("closeDialog2", false);
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(this.imageUrl, " this.imageUrl");

      let imgUrl = {
        name: file.response.data.sourceFileName,
        url: file.response.data.url,
      };
      this.ImgList = imgUrl;
    },

    async initPay(obj) {
      this.payList = obj;
      // console.log(obj, "查看obj");
      if (obj.status == 6 || obj.status == 4) {
        let params = {
          applyId: obj.applyId,
        };
        let res = await selectBondDetail(params);
        this.form.bondNotice = res.data.bondNotice;
        this.form.rejectNotice = res.data.rejectNotice;
        // console.log(res.data,'查看res.data')
        this.imageUrl =
          this.baseUrl +
          "admin/file/get?ossFilePath=" +
          JSON.parse(res.data.bondFile).url;
        console.log(JSON.parse(res.data.bondFile), "查看往 this.ImgList赋值");
        this.ImgList = JSON.parse(res.data.bondFile);
        console.log(this.ImgList, "this.ImgList");
      }
    },
    //上传
    async uploadBond() {
      let params = {
        bondNotice: this.form.bondNotice,
        applyId: this.payList.applyId,
        procurementId: this.payList.procurementId,
        procurementCode: this.payList.procurementCode,
        bondFile: JSON.stringify(this.ImgList),
      };
      // console.log(params, "查看最新的params");
      let res = await uploadBond(params);
      if (res.status == 200) {
        if (res.rel == true) {
          this.$message.success(res.message);
        } else {
          this.$message.info(res.message);
        }

        this.$emit("closeDialog2", false);
      }
    },
  },
};
</script>
<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.payment {
  .el-form {
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    .allBtn {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }
}
</style>
