<template>
  <div class="PeasantCollectivePurchaseSignUp">
    <h3>农资集采报名记录</h3>
    <!-- 根据条件搜索    -->
    <div class="searchItem">
      <el-form
        :model="form"
        label-position="center"
        label-width="80px"
        inline
        ref="form"
      >
        <el-form-item label="项目编号">
          <el-input
            style="width: 190px"
            v-model="form.procurementCode"
            placeholder="项目编号"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="标题">
          <el-input
            style="width: 190px"
            v-model="form.title"
            placeholder="标题"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="农资类别">
          <!-- <el-select v-model="form.agriculturalType" style="width: 200px">
            <el-option
              v-for="(item, index) in types"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></el-option
          ></el-select> -->
          <el-cascader
            ref="address"
            v-model="form.secondId"
            :options="TypeList"
            @change="handleChange"
            :show-all-levels="false"
            :props="props"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="农资状态">
          <el-select v-model="form.status" style="width: 200px">
            <el-option
              v-for="(item, index) in statesList"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></el-option
          ></el-select>
        </el-form-item>

        <div class="Btn">
          <el-button class="query-btn" type="primary" @click="search"
            >搜索</el-button
          >
          <el-button class="query-btn" type="primary" @click="reset"
            >重置</el-button
          >
        </div>
      </el-form>
    </div>
    <LookDetail
      v-if="detailsShow"
      @closeDialog="closeDialog"
      ref="detailsShow"
    />
    <PayMent v-if="PayMent" @closeDialog2="closeDialog2" ref="PayMent" />
    <table-page
      :data="tableData"
      :columns="columns"
      :currentPage="query.pnum"
      :pageSize="query.psize"
      :total="total"
      :border="true"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
    >
      <template slot="title" slot-scope="scope">
        <span @click="LookSignUp(scope.row)"
          ><div>
            {{ scope.row.title }}
          </div></span
        >
      </template>
      <template slot="status" slot-scope="scope">
        {{
          scope.row.status == 0
            ? "待支付"
            : scope.row.status == 1
            ? "超时未支付"
            : scope.row.status == 2
            ? "报名成功"
            : scope.row.status == 3
            ? "已取消报名"
            : scope.row.status == 4
            ? "保证金审核中"
            : scope.row.status == 5
            ? "审核成功"
            : scope.row.status == 6
            ? "审核失败"
            : ""
        }}
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button
          type="text"
          size="small"
          @click="detailsClick(scope.row.applyId)"
          >查看</el-button
        >
        <el-button type="text" size="small" @click="goPayment(scope.row)">
          {{
            scope.row.status == "0"
              ? "上传保证金支付凭证"
              : scope.row.status == "6" || scope.row.status == "4"
              ? "重新上传保证金"
              : ""
          }}</el-button
        >

        <el-button type="text" size="small" @click="updateCancel(scope.row)">{{
          scope.row.isTime == "true" ? "取消报名" : ""
        }}</el-button>
      </template>
    </table-page>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LookDetail from "./components/details";
import PayMent from "./components/payment";
import TablePage from "@/components/table/table-page";
import {
  selectApplyRecordList,
  updateCancel,
  nodeList,
  treeList,
} from "@/api/CollectivePurchase";
import { PeasantCollectivePurchaseSignUp } from "../tableColumn";

export default {
  name: "NowIndex",
  components: { TablePage, LookDetail, PayMent },
  data() {
    return {
      form: {
        procurementCode: "",
        secondId: "",
        status: "",
        title: "",
      },
      TypeList: [],
      tableData: [],
      columns: PeasantCollectivePurchaseSignUp,
      query: {
        current: 1,
        size: 10,
      },
      total: 1,
      detailsShow: false,
      PayMent: false,
      statesList: [
        {
          value: 0,
          label: "待支付",
        },
        {
          value: 1,
          label: "超时未支付",
        },
        {
          value: 2,
          label: "报名成功",
        },
        {
          value: 3,
          label: "已取消报名",
        },
        {
          value: 4,
          label: "保证金审核中",
        },
        {
          value: 5,
          label: "审核成功",
        },
        {
          value: 6,
          label: "审核失败",
        },
      ],
      props: {
        value: "secondId",
        label: "label",
        children: "children",
      },
      subsetList: [],
      oneList: [],
    };
  },
  watch: {
    topSelectAreacode: function () {
      if (
        this.topSelectAreacode.length > 4 &&
        this.topSelectAreacode !== undefined
      ) {
        this.selectApplyRecordList();
      } else {
        this.$message.info("请选择所属区县，查看对应产品");
      }
    },
  },
  mounted() {
    this.selectApplyRecordList();
    //node树查询
    this.$nextTick(() => {
      this.nodeList();
      this.treeList();
    });
  },
  computed: {
    // ...mapGetters(["topSelectAreacode"]),
    ...mapGetters(["identityObj", "topSelectAreacode"]),
  },
  methods: {
    //分页单页切换
    handleCurrentChange(page) {
      this.query.current = page;
      this.selectApplyRecordList();
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        current: 1,
        size: size,
      };
      this.selectApplyRecordList();
    },
    // 去支付
    goPayment(obj) {
      // this.$router.push({ name: "newPurchase", query: { c: data.xmbh } });
      // this.$router.push({ name: "pay", query: { data: applyId } });
      this.PayMent = true;
      this.$nextTick(() => {
        this.$refs.PayMent.initPay(obj);
      });
    },
    //查看详情
    detailsClick(applyId) {
      this.detailsShow = true;

      this.$nextTick(() => {
        this.$refs.detailsShow.intShow(applyId);
      });
    },
    //列表
    async selectApplyRecordList() {
      let params = {
        ...this.query,
      };
      let res = await selectApplyRecordList(params);
      this.tableData = res.data.records;
      this.total = Number(res.data.total);
      let Str = "first_crop_type";
      let res1 = await nodeList(Str);
      this.types = res1.data;
    },
    //关闭弹窗
    closeDialog() {
      this.detailsShow = false;
      this.selectApplyRecordList();
    },
    closeDialog2() {
      this.PayMent = false;
      this.selectApplyRecordList();
    },
    // 搜索
    async search() {
      let secondId = this.form.secondId[1];
      let params = {
        size: this.query.size,
        current: this.query.current,
        procurementCode: this.form.procurementCode,
        secondId: secondId,
        status: this.form.status,
        title: this.form.title,
      };
      console.log(params, "查看params");
      let res = await selectApplyRecordList(params);
      this.tableData = res.data.records;
    },
    async reset() {
      this.form = {
        procurementCode: "",
        agriculturalType: "",
        status: "",
        title: "",
      };

      let res = await selectApplyRecordList(params);
      this.tableData = res.data.records;
    },
    // 取消报名
    async updateCancel(obj) {
      console.log(obj, "查看合集");
      if (obj.status == 2 || obj.status == 0) {
        let params = {
          applyId: obj.applyId,
        };

        this.$confirm("是否进行修改?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await updateCancel(params);
            this.selectApplyRecordList();
            this.$message({
              type: "success",
              message: res.msg,
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    //查看详情
    LookSignUp(obj) {
      this.$router.push({
        name: "Preview",
        query: { data: obj.procurementId },
      });
    },
    // 农资规格管理-一级种类字典查询
    async nodeList() {
      let params = "first_crop_type";
      let res = await nodeList(params);
      this.oneList = res.data;
    },
    //农资规格管理-种类树状图查询
    async treeList() {
      this.childrenList = [];
      // let params = {
      //   areaCode: this.topSelectAreacode,
      // };
      let params = {};
      let res = await treeList(params);
      this.subsetList = res.data;
      this.allNode();
    },
    //组合成一个节点树的列表
    allNode() {
      let firstList = this.oneList;
      let secondList = this.subsetList;
      this.$nextTick(() => {
        firstList.forEach((item) => {
          const newItem = {
            ...item,
            children: [],
          };
          secondList.forEach((items) => {
            if (item.id === items.parentId) {
              items.label = items.secondName;
              items.value = items.secondId;
              newItem.children.push(items);
            }
          });
          this.TypeList.push(newItem);
        });
        this.TypeList.forEach((item) => {
          item.secondId = item.value;
        });
      });
    },
    //农资类型下拉框选择
    handleChange(value) {
      this.typeIndex = value;
      console.log(this.typeIndex, "查看this.typeIndex");
      console.log(this.typeIndex);
    },
  },
};
</script>

<style lang="less" scoped>
</style>
